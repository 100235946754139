// 工单状态
const workOrderStatus = [
  {
    value: 1,
    label: "待受理",
  },
  {
    value: 2,
    label: "已接单",
  },
  {
    value: 3,
    label: "处理中",
  },
  {
    value: 4,
    label: "已完成",
  },
];
// 用户评价
const satisfactionStatus = [
  {
    value: 1,
    label: "非常满意",
  },
  {
    value: 2,
    label: "满意",
  },
  {
    value: 3,
    label: "不满意",
  },
];
import { mapHelper } from "@/utils/common.js";

const { map: workOrderStatusMap, setOps: workOrderStatusOps } =
  mapHelper.setMap(workOrderStatus);
const { map: satisfactionStatusMap, setOps: satisfactionStatusOps } =
  mapHelper.setMap(satisfactionStatus);

export {
  workOrderStatus,
  workOrderStatusMap,
  workOrderStatusOps,
  satisfactionStatus,
  satisfactionStatusMap,
  satisfactionStatusOps,
};
