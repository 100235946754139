<template>
  <div class="page">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #searchSlot>
        <v-select
          @change="workTypechange"
          @clear="workTypeClear"
          clearable
          :options="workOrderTypeList"
          v-model="searchParam.workTypeName"
          label="工单类型"
        />
        <v-select
          clearable
          :options="eventTypeList"
          v-model="searchParam.incidentName"
          label="事件类型"
        />
        <!-- <v-select
          clearable
          :options="workOrderStatus"
          v-model="searchParam.workOrderStatus"
          label="工单状态"
        /> -->
        <v-input
          label="接单人昵称"
          v-model="searchParam.receiverName"
          placeholder="请输入接单人昵称"
        />
        <v-input
          label="转派人昵称"
          v-model="searchParam.redeployName"
          placeholder="请输入转派人昵称"
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import { workOrderTypeListUrl, redeployRecordListUrl } from "./api.js";
import { Message, MessageBox } from "element-ui";
import {
  workOrderStatus,
  workOrderStatusMap,
  workOrderStatusOps,
  satisfactionStatus,
  satisfactionStatusMap,
  satisfactionStatusOps,
} from "./map.js";
export default {
  name: "transferRecord",
  data() {
    return {
      workOrderStatus,
      satisfactionStatus,
      tableUrl: redeployRecordListUrl,
      searchParam: {
        workTypeName: null,
        incidentName: null,
        workOrderStatus: null,
        receiverName: null,
        redeployName: null,
      },
      workOrderTypeList: [], //筛选条件工单类型list
      eventTypeList: [], //筛选条件事件类型list
      headers: [
        {
          prop: "workOrderId",
          label: "工单编号",
        },
        {
          prop: "receiverName",
          label: "接单人",
        },
        {
          prop: "redeployName",
          label: "转派人",
        },
        {
          prop: "workTypeName",
          label: "工单类型",
        },
        {
          prop: "incidentName",
          label: "事件类别",
        },
        {
          prop: "redeployExplain",
          label: "转派说明",
        },
        {
          prop: "createTime",
          label: "转派时间",
        },
        {
          prop: "updateTime",
          label: "接单时间",
        },
      ],
    };
  },
  mounted() {
    this.getWorkOrderTypeList();
  },
  methods: {
    workTypechange(value) {
      //工单类型切换
      this.eventTypeList = [];
      this.searchParam.incidentName = null;
      this.workOrderTypeList.forEach((item, index) => {
        if (item.name == value) {
          item.list.forEach((item2, index2) => {
            item2.value = item2.incidentName;
            item2.label = item2.incidentName;
            this.eventTypeList.push(item2);
          });
        }
      });
    },
    workTypeClear() {
      //工单类型清空
      this.searchParam.workTypeName = null;
      this.searchParam.incidentName = null;
      this.eventTypeList = [];
    },
    getWorkOrderTypeList() {
      //获取工单类型列表
      let params = {
        pageSize: 1000000,
        curPage: 1,
      };
      this.$axios
        .get(`${workOrderTypeListUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            console.log(res);
            res.data.records.forEach((item, index) => [
              (item.value = item.name),
              (item.label = item.name),
              this.workOrderTypeList.push(item),
            ]);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  box-sizing: border-box;
  height: 100%;
}
</style>
